import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import { Email, PhotoCamera, Favorite } from '@material-ui/icons';
import { R_HEADER_TOP_HEIGHT, R_HEADER_BOT_HEIGHT } from './constant';
import { name, city, url_project } from "../../../project.json"


function Header() {
  const { height, mainColor, favoriteList, totalWidth, nbrLots } = useState(hive.state)
  const isMouseOverFavorite = useState(false)

  const iconStyle = {
    width: height.get() * 0.065 * 0.55,
    height: height.get() * 0.065 * 0.55,
  }

  return <div style={{
    height: height.get() * R_HEADER_TOP_HEIGHT
  }}>
    <div style={{
      width: '100%',
      background: "#F9F9F9",
      height: height.get() * 0.065,
      display: "flex"
    }}>

      {/* Logo */}
      <div style={{
        width: "12%",
        height: height.get() * 0.06,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
        <div style={{
          width: "87%",
          height: "95%",
          backgroundImage: "url(/bati_logo.png)",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}>
        </div>
      </div>

      {/* Infos */}
      <div style={{
        width: "40%",
        height: height.get() * 0.06,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        cursor: url_project ? "pointer" : "initial"
      }}
        onClick={() => {
          if (url_project) {
            window.location.href = url_project
          }
        }}
      >
        <div style={{
          width: "100%",
          height: height.get() * 0.05,
          borderRight: "1px solid #C2C2C2",
          display: "flex",
        }}>
          <div style={{
            width: "85%",
            color: "#9D9D9D",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
            <div style={{
              fontFamily: "MuktaBold",
              fontSize: totalWidth.get() * 0.05 * 0.2 + "px",
              lineHeight: height.get() * 0.05 * 0.63 + "px",
            }}>
              {name}
            </div>
            <div style={{
              fontSize: totalWidth.get() * 0.05 * 0.15 + "px",
              lineHeight: height.get() * 0.05 * 0.40 + "px",
            }}>
              {city}
            </div>
          </div>


          {/* <div style={{
            width: "15%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}>
            <div style={{
              cursor: "pointer",
              backgroundColor: `#${mainColor.get()}`,
              backgroundImage: "url(/gui/i.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "40%",
              width: height.get() * 0.05 * 0.6,
              height: height.get() * 0.05 * 0.6,
              borderRadius: height.get() * 0.05 * 0.6 * 0.5,
              lineHeight: height.get() * 0.05 * 0.6 + "px",
              color: "white",
              fontFamily: "'Lemonada', cursive",
              // fontStyle : "italic",
              fontSize: height.get() * 0.05 * 0.6 * 0.7 + "px",
              textAlign: "center",
            }}>

            </div>
          </div> */}


        </div>
      </div>

      <div style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "69%",
      }}>

        {/* Icons */}
        <div style={{
          height: height.get() * 0.065,
          width: "17%",
          display: "flex",
          justifyContent: "space-around",
          color: "#9D9D9D",
        }}>
          {Array.from({ length: 3 }).map((e, i) =>
            <div key={i} style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}>
              {/* {i === 0 && <Email style={iconStyle}></Email>}
              {i === 1 && <PhotoCamera style={iconStyle}></PhotoCamera>} */}
              {(i === 2 && nbrLots.get() > 0) && <>
                <div
                  onClick={() => {
                    if (favoriteList.length > 0) {
                      hive.action("setScene", "favorites")
                    }
                  }}
                  onMouseEnter={() => isMouseOverFavorite.set(true)}
                  onMouseLeave={() => isMouseOverFavorite.set(false)}
                  style={{
                    marginTop: height.get() * 0.01,
                    cursor: favoriteList.length > 0 ? "pointer" : "initial",
                  }}>
                  <div style={{
                    position: "absolute",
                    width: height.get() * 0.025,
                    height: height.get() * 0.025,
                    lineHeight: height.get() * 0.025 + "px",
                    borderRadius: height.get() * 0.025 * 0.5,
                    textAlign: "center",
                    background: "red",
                    color: "white",
                    fontFamily: "MuktaBold",
                    marginLeft: height.get() * 0.025,
                    marginTop: -height.get() * 0.007,
                    visibility: favoriteList.length > 0 ? "initial" : "hidden",
                    fontSize: height.get() * 0.02,
                  }}>
                    {favoriteList.length}
                  </div>
                  <Favorite style={{
                    ...iconStyle,
                    color: (isMouseOverFavorite.get() && favoriteList.length > 0) ? `#${mainColor.get()}` : "inherit"
                  }}></Favorite>
                </div>
              </>
              }
            </div>
          )}
        </div>

        {/* blank space */}
        <div style={{
          width: "5%",
        }}>
        </div>

        {/* Contact */}
        {/* <div style={{
          height: height.get() * 0.065,
          width: "14%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}>
          <div style={{
            height: height.get() * 0.065 * 0.7,
            lineHeight: height.get() * 0.065 * 0.7 + "px",
            width: "90%",
            background: `#${mainColor.get()}`,
            borderRadius: height.get() * 0.065 * 0.7 * 0.31 + "px",
            color: "white",
            textAlign: "center",
            fontSize: height.get() * 0.065 * 0.7 * 0.35 + "px",
            cursor: "pointer",
          }}>
            CONTACTEZ NOUS
            </div>
        </div> */}

        {/* blank space */}
        <div style={{
          width: "5%",
        }}>
        </div>

      </div>

    </div>
    <div style={{
      width: '100%',
      height: height.get() * R_HEADER_BOT_HEIGHT,
      background: `#${mainColor.get()}`
    }}>
    </div>
  </div >
}

export default Header;